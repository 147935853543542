body {
  margin                 : 0;
  font-family            : "Circular Pro", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide froala editor mark */
p[data-f-id="pbf"] {
  display: none;
}

@media (min-width: 350px) {
  .infeed {
    height: 100px !important;
  }
}
@media (min-width: 500px) {
  .infeed {
    height: 150px !important;
  }
}
@media (min-width: 800px) {
  .infeed {
    height: 180px !important;
  }
}