.App {
  overflow-x  : hidden;
  margin-right: 0;
  margin-left : 0;
}

.App-logo {
  height        : 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.menuButton {
  transition : 0.3s;
  user-select: none;
}

.menuButton:hover {
  opacity: 0.68;
}

.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  margin                 : 0;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.active {
  overflow: hidden;
}

#main {
  transition: 0.3s;
}

.wrapper {
  min-height : 100vh;
  overflow-x : hidden;
  overflow-y : hidden;
  padding-top: 3.5rem;
}

.cardImage {
  background-position: center;
  background-repeat  : no-repeat;
  background-size    : contain;
  width              : 100%;
  height             : 11rem;
}

.linkCard {
  transition      : 0.3s;
  padding         : 0;
  background-color: #fdfdfd;
}

.story {
  border: 1px solid rgba(0, 0, 0, 0.13);
}

.linkCard:hover {
  background-color: #e9e9e9;
}

.storyImage {
  background-position: center;
  background-size    : contain;
  background-repeat  : no-repeat;
}

.sideAdvertisement {
  /* background-color: #212121; */
  /* color           : white; */
  width : 100%;
  height: 280px;
}

.verticalAdvertisement {
  /* background-color: #212121; */
  /* color           : white; */
  width : 100%;
  height: 100px;
}

.navbar,
.navbar-button {
  background-color: #181716;
  color           : #fcfcfc;
  border          : 1px solid #181716;
  user-select     : none;
}

.navbar {
  height  : 3.5rem;
  position: fixed;
  width   : 100%;
  z-index : 100;
  padding : 0 !important;
}

.navbarIcon {
  font-size: 1.3rem;
}

#searchbox-label,
#searchbox {
  color: white;
}

.slides-image {
  min-height: 300px;
}

g.text-group {
  display: none;
}

.sidebar-item {
  height         : 40px;
  cursor         : pointer;
  color          : #fcfcfc;
  text-decoration: none;
  transition     : 0.1s;
}

button.sidebar-item {
  background-color: #212424;
  color           : #fcfcfc;
  border          : none;
  border-radius   : 0;
}

button.sidebar-item:hover {
  background-color: #212424;
  color           : #fcfcfc;
}

.sidebar-item:hover {
  border-left: 5px solid rgb(11, 60, 255);
}

/* a {
  color          : inherit;
  text-decoration: inherit;
} */

header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault.MuiPaper-elevation4 {
  box-shadow: none;
}

.title {
  border-top   : 4px solid black;
  border-bottom: 1px solid black;
  user-select  : none;
}

.dropdown-menu {
  background-color: #212424;
}

.dropdown-item {
  color: #fcfcfc;
}

.carnival-item {
  transition: 0.8s;
}

.carnival-item:hover {
  transform    : scale(102%);
  border-bottom: 3px solid black;
}

.border-div {
  width           : 60%;
  height          : 2px;
  margin          : 10px;
  background-color: gray;
  border-radius   : 50%;
  transition      : 1s;
}

.border-div.hovered {
  width: 100%;
}

.more-button {
  transition : 0.1s;
  user-select: none;
  cursor     : pointer;
}

.more-button:hover {
  transform: scale(100.5%);
}

.modal-content {
  border: none;
}

.footer-advert {
  width : 200px;
  height: 110px;
  margin: 1rem;
}

.instagram,
.facebook,
.twitter {
  cursor     : pointer;
  user-select: none;
}

.instagram {
  color: #C13584;
}

.facebook {
  color: #3b5998;
}

.twitter {
  color: rgb(0, 172, 238);
}

.igButton {
  background-color: #C13584;
  color           : white;
}

.fbButton {
  background-color: #3b5998;
  color           : white;
}

.ytButton {
  background-color: red;
  color           : white;
}

footer {
  background-color: #e6e6e6;
  color           : #181716;
  bottom          : 0;
}

.modal-search-background {
  background-color: #181716;
}

.flag:hover {
  color      : red;
  cursor     : pointer;
  user-select: none;
}

.rateSectionItem:hover,
.pointer {
  cursor: pointer;
}

.card-item {
  transition: 0.3s;
}

.card-item:hover,
#sidebar {
  box-shadow      : 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #181716;
  color           : #fcfcfc;
}

#sidebar {
  min-height: 100vh;
}

*:focus {
  outline: none !important;
}

.profileSignin {
  min-height     : 95vh;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  background     : #00d2ff;
  /* fallback for old browsers */
  background     : -webkit-linear-gradient(to right, #928DAB, #00d2ff);
  /* Chrome 10-25, Safari 5.1-6 */
  background     : linear-gradient(to right, #928DAB, #00d2ff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.signinText {
  font-weight          : 900;
  color                : white;
  background-blend-mode: color-burn;
  background-color     : rgba(250, 250, 250, 0.2);
  width                : 100%;
  text-align           : center;
  padding              : 2.2rem;
}

.zoomContainer {
  overflow: hidden;
}

.zoom {
  transition: 1s;
}

.quote {
  color: rgb(23, 32, 42);
}

.zoom:hover {
  -ms-transform    : scale(1.05);
  -moz-transform   : scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform     : scale(1.05);
  transform        : scale(1.05);
}

#searchModal {
  background: #212121;
}

@media (min-width: 771px) {
  .searchField {
    width: 27vw;
  }

  .landingPageImage {
    width : 100%;
    height: 350px;
  }

  .landingPageSideImage {
    width : 100%;
    height: 180px;
  }
}

@media (max-width: 770px) {
  .searchField {
    width: 50vw;
  }

  .landingPageImage {
    width : 100%;
    height: 50vw;
  }
}

.landingPageArrow {
  transition: 1s;
}

.landingPageLink:hover .landingPageArrow {
  margin-left: 2.5rem;
}

.send.false {
  display: none;
}

.tag {
  border-radius   : 10px;
  transition      : 0.3s;
  background-color: #e3f2fd;
  user-select     : none;
}

.tag:hover {
  background-color: #bbdefb;
}

.tag.active {
  background-color: #bbdefb;
}

.background {
  background-repeat  : no-repeat;
  background-size    : contain;
  background-position: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Circular Pro", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

/* span,
p {
  font-family   : "Tiempos Text", Georgia, "Times New Roman", serif;
  text-transform: none;
} */

/* Search box landign page react-autosuggest */
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 15px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {

  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: rgb(24, 23, 22);
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
  color: rgb(24, 23, 22);
}

/* CSSTransition Styles: */
.animated-node-enter {
  opacity: 0;
}
.animated-node-enter-active {
  opacity: 1;
  transition: opacity 800ms;
}
.animated-node-exit {
  opacity: 1;
}
.animated-node-exit-active {
  opacity: 0;
  transition: opacity 800ms;
}

.orange-button {
  background-color: #FFAC1C !important;
}